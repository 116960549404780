<template>
    <div  id="upload-form" class="page-root">
        <el-form ref="dataForm" :model="dataForm" label-width="100px" label-suffix=":" :rules="rules">
            <el-form-item label="公司" prop="">
                <el-select v-model="dataForm.company" prop="company">
                    <el-option-group v-for="[key, value] in Object.entries(userOrgs)" :label="key" :key="key">
                        <el-option v-for="org in value" :key="org.org_id" :label="org.org_name" :value="org.org_id">
                        </el-option>
                    </el-option-group>
                </el-select>
            </el-form-item>
            <el-form-item label="送检单位" prop="">
                <el-input v-model="dataForm.send_company"></el-input>
            </el-form-item>
            <el-form-item label="样品名称" prop="sample">
                <el-input v-model="dataForm.sample"></el-input>
            </el-form-item>
            <el-form-item label="检测项目" prop="check_item">
                <el-select v-model="dataForm.check_item" @change="itemSelected">
                    <el-option v-for="item in checkItems" :key="item" :value="item" ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="参考标准" prop="ref_info">
                <el-input v-model="dataForm.ref_info"></el-input>
            </el-form-item>
            <el-form-item label="检测人" prop="checker">
                <el-input v-model="dataForm.checker"></el-input>
            </el-form-item>
            <el-form-item label="供应商" prop="supplier">
                <el-input v-model="dataForm.supplier"></el-input>
            </el-form-item>
            <el-form-item label="样品编号" prop="sample_no">
                <el-input v-model="dataForm.sample_no"></el-input>
            </el-form-item>
            <el-form-item label="检测时间" prop="check_time">
                <el-date-picker  v-model="dataForm.check_time" type="datetime"></el-date-picker>
            </el-form-item>
            <el-form-item label="检测值" prop="check_value">
                <el-input @change="valueInputed" v-model="dataForm.check_value" style="width:10vw;"></el-input>
                {{dataForm.unit}}
                &nbsp;<span style="font-size:2vh;color:red">限制值:{{dataForm.standard_value}}</span> 
            </el-form-item>
            <el-form-item label="检测结果" prop="check_result" >
                <el-select v-model="dataForm.check_result"  >
                    <el-option label="合格" :value="1" />
                    <el-option label="不合格" :value="2" />
                </el-select>
            </el-form-item>
            <el-form-item label="检测费用" prop="fee">
                <el-input v-model="dataForm.fee"></el-input>
            </el-form-item>
            <el-form-item label="原始值1" prop="a1">
                <el-input v-model="dataForm.a1"></el-input>
            </el-form-item>
            <el-form-item label="原始值2" prop="a2">
                <el-input v-model="dataForm.a2"></el-input>
            </el-form-item>
            <el-form-item  >
                <el-button type="primary" @click="submit(refForm)">提交</el-button>
                <el-button  @click="refForm.resetFields()">重置</el-button>
            </el-form-item>
        </el-form>
       
    </div>
</template>

<script>
import { renameSync } from 'fs';
import { uploadData } from 'network/checkdata';
import { getCheckItem } from 'network/info';
import { deepCopy } from '../../assets/js/utils/utils';

export default {
    data() {
        return {
            dataForm: {
                company: "",
                send_company: "",
                sample: "",
                check_item: "",
                ref_info: "",
                checker: "",
                supplier: "",
                sample_no: "",
                check_time: new Date(),
                check_value: "",
                unit: "",
                fee: 0,
                a1: 0,
                a2: 0,
                standard_value:0,
                check_result:""
            },
            // dataForm: {
            //     company: 1,
            //     send_company: "摊位1",
            //     sample: "白菜",
            //     check_item: "4-氯苯氧乙酸钠",
            //     ref_info: "GB210",
            //     checker: "df检测员",
            //     supplier: "供应商1",
            //     sample_no: "CN211",
            //     check_time: new Date(),
            //     check_value: 0.05,
            //     unit: "ppb",
            //     fee: 0,
            //     a1: 0,
            //     a2: 0,
            //     standard_value:0,
            //     check_result:1
            // },
            rules: {
                company: [{
                    required: true,
                    message: "公司不能为空",
                    trigger: "change",
                }],
                sample:[{
                    required: true,
                    message: "不能为空",
                    trigger: "change",
                }],
                check_item:[{
                    required: true,
                    message: "不能为空",
                    trigger: "change",
                }],
                // refInfo: [{
                //     required: true,
                //     message: "不能为空",
                //     trigger: "change",
                // }],
                checker: [{
                    required: true,
                    message: "不能为空",
                    trigger: "change",
                }],
                check_time: [{
                    required: true,
                    message: "不能为空",
                    trigger: "change",
                }],
                check_value: [{
                    required: true,
                    message: "不能为空",
                    trigger: "change",
                }],
                check_result: [{
                    required: true,
                    message: "不能为空",
                    trigger: "change",
                }]
            }
        }
    },
    computed: {
        userOrgs() {
            return this.$store.getters["companies/userCompanies"];
        },
        refForm(){
            return this.$refs.dataForm
        },
        checkItems(){
      return this.$store.getters["checkItems/checkItemList"]
    }
    },
    methods: {
        submit(refForm) {
            if(refForm)
            refForm.validate(valid=>{
                if(valid){
                    let data=deepCopy(this.dataForm)
                    data.check_time=data.check_time.getTime()
                    uploadData(data).then(res=>{
                        if(res.data.status==1)
                this.$message.success("上传成功ID:"+res.data.content)
                refForm.resetFields()
            },e=>{console.log(e)})
                }else {
            this.$message.warning("请检查筛选条件");
          }
            })
        },
        itemSelected(name){
            if(name==undefined||name=="")
                return
            getCheckItem(name).then(
                res=>{
                    this.dataForm.unit=res.unit
                    this.dataForm.standard_value=res.standard_value
                    if(this.dataForm.check_value!="")
                    this.qualified(res.standard_value,this.dataForm.check_value)
                },
                e=>{console.log(e)}
            )
        },
        qualified(s,v){
            console.log("h")
            if(s>v){
                this.dataForm.check_result=1
            }else
            this.dataForm.check_result=2
        },
        valueInputed(val){
            if(this.dataForm.standard_value<0)
                return
            this.qualified(this.dataForm.standard_value,val)
        }
    },
    created() { },
    mounted() {
    }
}
</script>

<style lang="less" scoped>
.page-root {
    width: 90vw;
    height: 86vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #071f63;
    color: #f2f2f2;
    .el-form {
        padding-top: 20px;
        align-items: center;
        width: 800px;
    }
   
}


#upload-form /deep/ .el-form-item__label {
  color: #f2f2f2;
  font-size: 1.5vh;
  line-height: 3vh;
}
//输入框样式
#upload-form /deep/ .el-input__wrapper {
  height: 3.2vh;
  background-color: #0c59b9;
  color: #f2f2f2 !important;
  box-shadow: none;
}
#upload-form /deep/ input {
  color: #f2f2f2;
}
</style>